<template>
  <div class="card card-custom gutter-b">
    <div class="card-header flex-nowrap border-0">
      <div class="card-title">
        <h3 class="card-label">
          Linea de tiempo del proceso de revisión del perfil
        </h3>
      </div>
      <div class="card-toolbar"></div>
    </div>
    <v-divider class="my-0"></v-divider>
    <div class="card-body">
      <v-timeline align-top dense>
        <v-timeline-item
          v-for="(item, index) in evaluationTrackings"
          :key="index"
          :color="colors[item.evaluation_status_id % 17]"
          small
        >
          <v-row class="pt-1">
            <v-col cols="3">
              <strong>{{ item.created_at_formatted }}</strong>
            </v-col>
            <v-col>
              <strong>{{ item.evaluation_status }}</strong>
              <div class="text-caption">
                {{ item.user_full_name }}
              </div>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReviewProcessTimeLine",
  props: {
    evaluationTrackings: {
      type: Array,
    },
  },
};
</script>
