<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="550"
    persistent
    scrollable
  >
    <v-form>
      <v-card>
        <v-toolbar dark color="primary" class="elevation-0 px-3">
          <span class="headline">Editar detalles de la evaluación</span>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row class="px-4 pt-6">
              <v-col class="py-0">
                <div class="black--text mb-1 flex-column">
                  <form-group
                    name="nombre de la evaluación"
                    :validator="
                      $v.subEvaluation.activity_type_evaluation_type_id
                    "
                  >
                    <v-select
                      slot-scope="{ attrs }"
                      v-bind="attrs"
                      label="Nombre de la evaluación"
                      placeholder="Selecciona un tipo de evaluación"
                      :items="evaluationTypes"
                      item-text="evaluation_type"
                      item-value="id"
                      required
                      filled
                      rounded
                      dense
                      background-color="grey lighten-4"
                      color="black"
                      v-model="subEvaluation.activity_type_evaluation_type_id"
                    ></v-select>
                  </form-group>
                </div>
              </v-col>
            </v-row>
            <v-row class="px-4">
              <v-col class="py-0">
                <div class="black--text mb-1 flex-column">
                  <form-group
                    name="descripción"
                    :validator="$v.subEvaluation.description"
                  >
                    <v-textarea
                      slot-scope="{ attrs }"
                      v-bind="attrs"
                      filled
                      rounded
                      dense
                      background-color="grey lighten-4"
                      color="black"
                      counter
                      :label="`Descripción de la evaluación`"
                      auto-grow
                      rows="2"
                      v-model="subEvaluation.description"
                    ></v-textarea>
                  </form-group>
                </div>
              </v-col>
            </v-row>
            <v-row class="px-4">
              <v-col class="py-0">
                <div class="black--text mb-1 flex-column">
                  <form-group
                    name="instrumento de evaluación"
                    :validator="$v.subEvaluation.evaluation_instrument_document"
                  >
                    <v-file-input
                      slot-scope="{ attrs }"
                      v-bind="attrs"
                      label="Instrumento de evaluación"
                      prepend-icon=""
                      prepend-inner-icon="mdi-file-pdf-box"
                      filled
                      rounded
                      dense
                      background-color="grey lighten-4"
                      color="black"
                      accept="application/pdf"
                      truncate-length="15"
                      hint="El instrumento de evaluación no es obligatorio"
                      persistent-hint
                      v-model="subEvaluation.evaluation_instrument_document"
                    >
                      <template v-slot:selection="{ index, text }">
                        <v-chip v-if="index < 2" color="primary" label small>{{
                          text
                        }}</v-chip>
                      </template>
                    </v-file-input>
                  </form-group>
                </div>
              </v-col>
            </v-row>
            <v-row class="px-4">
              <v-col cols="12" sm="6" class="py-0">
                <div class="black--text mb-1 flex-column">
                  <form-group
                    name="descripción"
                    :validator="$v.subEvaluation.limit_date"
                  >
                    <v-menu
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      v-model="limitDateMenu"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <form-group
                          name="fecha límite"
                          :validator="$v.subEvaluation.limit_date"
                          v-bind="attrs"
                        >
                          <v-text-field
                            v-model="subEvaluation.limit_date"
                            label="Fecha límite"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            required
                            filled
                            rounded
                            dense
                            background-color="grey lighten-4"
                            color="black"
                            v-bind="attrs"
                            slot-scope="{ attrs }"
                            v-on="on"
                          ></v-text-field>
                        </form-group>
                      </template>
                      <v-date-picker
                        v-model="subEvaluation.limit_date"
                        :min="evaluationDateRange.startDate"
                        :max="evaluationDateRange.endDate"
                        @input="limitDateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </form-group>
                </div>
              </v-col>
              <!-- <v-col cols="12" sm="6" class="py-0">
                <div class="black--text flex-column">
                  <form-group
                    name="porcentaje"
                    :validator="$v.subEvaluation.percent"
                    :messages="errorMessages"
                  >
                    <v-text-field
                      slot-scope="{ attrs }"
                      v-bind="attrs"
                      label="Porcentaje"
                      required
                      filled
                      rounded
                      dense
                      background-color="grey lighten-4"
                      color="black"
                      type="number"
                      prepend-inner-icon="mdi-percent"
                      class="mt-0"
                      v-model.trim="$v.subEvaluation.percent.$model"
                    />
                  </form-group>
                </div>
              </v-col> -->
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider class="mt-0"></v-divider>
        <v-card-actions class="justify-end pt-0">
          <v-btn text outlined class="mr-1" @click="closeModal">Cerrar</v-btn>
          <v-btn
            color="success"
            outlined
            depressed
            :loading="isLoading"
            @click="submit"
            >guardar cambios</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { required, minValue, maxLength } from "vuelidate/lib/validators";
import subEvaluationRepository from "@/repositories/subEvaluationRepository";

export default {
  name: "UpdateSubEvaluationModal",
  data() {
    return {
      isLoading: false,
      dialog: false,
      errorMessages: {
        sumOfSubEvaluationsPercentMatchWeightLeft:
          "La sumatoria de los porcentajes de cada evaluación no debe exceder la ponderación del perfil especificada anteriormente.",
      },
      limitDateMenu: false,
      initialWeight: 0,
    };
  },
  props: {
    subEvaluation: {
      type: Object,
    },
    evaluationDateRange: {
      type: Object,
    },
    evaluationTypes: {
      type: Array,
    },
    canEdit: {
      type: Boolean,
    },
    weightLeft: {
      type: Number,
    },
  },
  methods: {
    update() {
      this.isLoading = true;
      subEvaluationRepository
        .updateSubEvaluation(
          this.subEvaluation.id,
          this.objectToFormData(this.subEvaluation)
        )
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data, hidding modal and cleaning selection if operation has been completed
          if (data.status) {
            this.$emit("reload-data");
            this.$emit("clean-selection");
            this.closeModal();
          }
        })
        .catch((error) => {
          console.log(error);
          this.fireToast({
            icon: "error",
            title: "No ha sido posible actualizar el registro 🙁",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar el formulario correctamente",
        });
        return;
      }
      this.update();
    },

    toggleModal() {
      this.dialog = !this.dialog;
    },

    closeModal() {
      this.toggleModal();
      this.$v.$reset();
    },
  },
  watch: {
    subEvaluation(newValue) {
      if (newValue) {
        this.initialWeight = newValue.percent;
      }
    },
  },
  validations: {
    subEvaluation: {
      activity_type_evaluation_type_id: {
        required,
      },
      description: {
        required,
        maxLength: maxLength(500),
      },
      limit_date: {
        required,
      },
      // 🚩 TODO: Validar el porcentajes
      percent: {
        // required,
        // minValue: minValue(1),
        // sumOfSubEvaluationsPercentMatchWeightLeft(value) {
        //   const weightLeft = this.weightLeft + this.initialWeight;
        //   const newWeight = parseInt(value);
        //   return weightLeft - newWeight == 0;
        // },
      },
      evaluation_instrument_document: {},
    },
  },
};
</script>
