<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-card
        class="ma-0 mx-auto pa-3 transition-swing"
        :class="`elevation-${hover ? 5 : 2}`"
      >
        <!--sub-evaluacion name-->
        <!-- colored headers -->
        <v-row :class="`${color} lighten-5 ma-0 pa-0`">
          <v-col cols="12" class="pb-0">
            <h4 class="card-title text-center m-0 mb-2">
              {{ subEvaluation.evaluation_type }}
            </h4>
          </v-col>
        </v-row>
        <!-- end::colored header -->

        <!-- begin::sub-evaluation weight -->
        <v-row class="ma-0 pa-0 mt-5">
          <v-col cols="12" class="d-flex justify-center ma-0 pa-0 ">
            <v-chip color="primary" outlined class=""
              >{{ subEvaluation.percent_formatted }} de
              {{ totalWeight }}</v-chip
            ></v-col
          >
        </v-row>
        <!-- end::sub-evaluation weight -->

        <!-- begin::deadline -->
        <v-row class="ma-0 mt-1 mb-3 pa-0">
          <v-col cols="12" class="d-flex justify-center mb-0 pb-0 ">
            <v-tooltip bottom :color="`${color} darken-1`">
              <template v-slot:activator="{ on, attrs }">
                <p class="text-center ma-0 pa-0" v-bind="attrs" v-on="on">
                  <v-icon :color="`${color} lighten-3`">mdi-calendar</v-icon>
                  {{ subEvaluation.limit_date_formatted }}
                </p>
              </template>
              <span class="text-center">Fecha límite de evaluación</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <!-- end::deadline -->

        <!-- begin::sub-evaluation description -->
        <v-row class="ma-0 pa-0 my-2">
          <v-col cols="12">
            <p
              :class="
                `grey lighten-4 text-center ma-0 pa-2 fixed-height rounded-lg `
              "
              v-if="subEvaluation.description"
            >
              {{ subEvaluation.description }}
            </p>
            <p class="text-center ma-0 pa-0 px-3" v-else>
              No hay descripción de actividad.
            </p>
          </v-col>
        </v-row>
        <!-- end::sub-evaluation description -->

        <!-- begin::Sub-evaluation instrument evaluation -->
        <v-row>
          <v-col
            class="p-0"
            v-if="subEvaluation.evaluation_instrument_document_path"
          >
            <a
              :href="evaluationInstrumentURL + subEvaluation.idE"
              target="_blank"
              class="mx-4 mb-3 d-flex justify-center align-center"
            >
              <v-icon class="mr-1" color="red">mdi-file-pdf-box</v-icon>
              <span class="font-weight-medium black--text"
                >Instrumento de evaluación</span
              >
            </a>
          </v-col>
        </v-row>
        <!-- end::Sub-evaluation instrument evaluation -->

        <!-- begin:: Contador evaluaciones pendientes -->
        <v-row v-if="subEvaluation.pending_score !== null">
          <v-col cols="12" class="d-flex justify-center font-weight-medium">
            {{ `${ setTitleText(evaluation.speciality_id) } pendientes` }}
          </v-col>
          <!-- Secciones académicas pendientes (Solo materias académicas) -->
          <v-col cols="12" class="pt-0" v-if="evaluation.speciality_id === 8">
            <v-chip
            v-for="pending in subEvaluation.pending_score?.academic_groups"
            :key="pending.key"
            class="m-1"
            :color="`${color} lighten-4`">
              {{ `${pending.name}: ${pending.count} estudiantes` }}
            </v-chip>
          </v-col>

          <!-- Grupos técnicos pendientes (Solo módulos técnicos) -->
          <v-col cols="12" class="pt-0" v-if="evaluation.speciality_id !== 8">
            <v-chip
            v-for="pending in subEvaluation.pending_score?.technical_groups"
            :key="pending.key"
            class="m-1"
            :color="`${color} lighten-4`">
              {{ `${pending.name}: ${pending.count} estudiantes` }}
            </v-chip>
          </v-col>
        </v-row>
        <!-- end: Contador evaluaciones pendientes -->


        <!-- begin::add scores button -->
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <v-btn
              :color="`${color} lighten-4`"
              @click="
                $emit('set-sub-evaluation', subEvaluation);
                $emit('show-select-group-modal');
              "
              v-show="showEnterScores"
            >
              visualizar o ingresar notas
              <v-icon right>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <!-- end::add scores button -->

        <!-- begin::button actions -->
        <v-col cols="12" class="pb-0 mb-2 d-flex justify-center align-center">
          <!--begin::Edit button-->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on"
                fab
                small
                @click="
                  setSubEvaluation();
                  updateSubEvaluation();
                "
                v-show="canEdit"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <!-- end::Edit button-->

          <!--begin::Delete button-->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="error"
                v-bind="attrs"
                v-on="on"
                fab
                small
                @click="
                  setSubEvaluation();
                  deleteSubEvaluation();
                "
                class="ml-3"
                v-show="canDelete"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
          <!--end::Delete button-->
        </v-col>
        <!-- end::button actions -->
      </v-card>
    </template>
  </v-hover>
</template>

<script>
export default {
  name: "subEvaluationCard",
  components: {},
  data() {
    return {};
  },
  props: {
    subEvaluation: { type: Object },
    evaluation: { type: Object },
    totalWeight: {
      type: String,
    },
    color: {
      type: String,
    },
    canEdit: { type: Boolean },
    canDelete: { type: Boolean },
    showEnterScores: { type: Boolean },
  },
  methods: {
    showMdlDeleteSubEvaluation() {
      this.$refs.mdlDeleteSubEvaluation.toggleModal();
    },
    showMdlSelectAcademicGroup() {
      this.$refs.mdlSelectAcademicGroup.toggleModal();
    },
    showMdlSelectTechnicalGroup() {
      this.$refs.mdlSelectTechnicalGroup.toggleModal();
    },
    setSubEvaluation() {
      this.$emit("set-sub-evaluation", this.subEvaluation);
    },
    deleteSubEvaluation() {
      this.$emit("delete-sub-evaluation", this.subEvaluation);
    },
    updateSubEvaluation() {
      this.$emit("update-sub-evaluation");
    },
    setTitleText(speciality_id) {
      if (speciality_id === 8) {
        return "Secciones";
      }
      if (speciality_id !== 8) {
        return "Grupos";
      }
    }
  },
  computed: {
    evaluationInstrumentURL() {
      let baseURL;
      if (process.env.APP_ENV === "production") {
        baseURL = process.env.API_URL;
      } else if (process.env.APP_ENV === "development") {
        baseURL = process.env.DEV_API_URL;
      } else {
        baseURL = process.env.LOCAL_API_URL;
      }
      return baseURL + "/sub-evaluations/evaluation-instrument/";
    },
  },
};
</script>
