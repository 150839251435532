<template>
  <div>
    <v-btn text class="mb-2" @click="goBack">
      <v-icon left>
        mdi-arrow-left
      </v-icon>
      Regresar a perfiles
    </v-btn>
    <!-- begin::Subject Information Card -->
    <SubjectInformationCard :subjectData="subject"></SubjectInformationCard>
    <!-- end::Subject Information Card -->

    <!-- begin::Card Evaluation Information -->
    <div class="card card-custom gutter-b ">
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            Información del perfil
            <span class="d-block text-muted pt-2 font-size-sm">
              Aqui se podrá visualizar la información del perfil y sus
              respectivas evaluaciones.
            </span>
          </h3>
        </div>
      </div>

      <div class="card-body">
        <!-- begin::Evaluation and subEvaluation Loader -->
        <div v-if="isLoading" style="height: 250px;">
          <v-row class="fill-height" align-content="center" justify="center">
            <v-col class="text-subtitle-1 text-center" cols="12">
              Cargando información del perfil y el listado de evaluaciones
            </v-col>
            <v-col cols="7" sm="6">
              <v-progress-linear
                color="blue darken-1"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </div>
        <!-- end::Evaluation and subEvaluation Loader -->
        <template v-else>
          <v-row>
            <v-col cols="12" sm="6" lg="4" xl="3">
              <v-card class="pa-5 elevation-3">
                <h2 class="card-title text-center mb-2">
                  {{ evaluation.activity_type }}
                </h2>

                <!--begin::Evaluation weight-->
                <div class="d-flex justify-center">
                  <v-chip outlined color="blue" dark
                    >{{ evaluation.percent }}%</v-chip
                  >
                </div>
                <!--end::Evaluation weight-->
                <!-- begin::Evaluation description -->
                <div class="mt-5 font-weight-medium text-center">
                  Descripción
                </div>
                <p
                  class="text-center mt-2 fixed-height grey lighten-4 rounded-lg"
                >
                  {{ evaluation.description }}
                </p>
                <!-- begin::Evaluation description -->
                <!-- begin::Evaluation observations -->
                <div class="mt-5 font-weight-medium text-center">
                  Observaciones
                </div>
                <p class="text-center mt-2 mx-4">
                  {{ evaluation.observations || "No hay ninguna observación" }}
                </p>
                <!-- begin::Evaluation observations -->
                <!-- begin::Evaluation period -->
                <v-row>
                  <v-col
                    cols="12"
                    lg="6"
                    class=" text-center font-weight-normal"
                  >
                    <p class="font-weight-medium ma-0">
                      Inicia:
                    </p>
                    <v-divider class="ma-1"></v-divider
                    >{{ evaluation.start_date_formatted }}
                  </v-col>
                  <v-col
                    cols="12"
                    lg="6"
                    class="  text-center font-weight-normal"
                  >
                    <p class="font-weight-medium ma-0">
                      Finaliza:
                    </p>
                    <v-divider class="ma-1"></v-divider
                    >{{ evaluation.end_date_formatted }}
                  </v-col>
                </v-row>
                <!-- end::Evaluation period -->
                <!-- begin::Evaluation instrument evaluation -->
                <a
                  :href="evaluationInstrumentURL + evaluation.idE"
                  target="_blank"
                  class="my-5 mx-4 d-flex justify-center align-center"
                >
                  <v-icon class="mr-1" color="red">mdi-file-pdf-box</v-icon>
                  <span class="font-weight-medium black--text"
                    >Instrumento de evaluación</span
                  >
                </a>
                <!-- end::Evaluation instrument evaluation -->
                <v-row>
                  <!--begin::Edit button-->
                  <v-col cols="12" class="d-flex justify-center mt-2">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                          fab
                          small
                          @click="showMdlUpdateEvaluation"
                          v-show="canEdit"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Editar</span>
                    </v-tooltip>
                  </v-col>
                  <!-- end::Edit button-->
                </v-row>
              </v-card>
            </v-col>
            <!-- begin::Right side - all subEvaluations -->
            <v-col
              v-for="(subEvaluation, index) in subEvaluations"
              :key="index"
              cols="12"
              sm="6"
              lg="4"
              xl="3"
            >
              <SubEvaluationCard
                :index="index"
                :subEvaluation="subEvaluation"
                :evaluation="evaluationData"
                :totalWeight="evaluation.percent_formatted"
                :color="orderedColors[subEvaluation.id % 17]"
                :canEdit="canEdit"
                :canDelete="canDeleteSubEvaluation"
                :showEnterScores="showEnterScores"
                @set-sub-evaluation="setSubEvaluation"
                @show-select-group-modal="showMdlSelectAcademicOrTechnicalGroup"
                @update-sub-evaluation="showMdlUpdateSubEvaluation"
                @delete-sub-evaluation="showMdlDelete"
              ></SubEvaluationCard>
            </v-col>

            <!-- end::Right side - all subEvaluations -->
          </v-row>
        </template>
      </div>
    </div>

    <!-- begin::Card Review Process -->
    <ReviewProccessCard
      :evaluationId="evaluation.id"
      :evaluationStatusId="evaluation.evaluation_status_id"
      :canReview="canReview"
      :canEnableReview="canEnableReview"
      :canEdit="canEdit"
      @reload-data="getEvaluation"
    ></ReviewProccessCard>
    <!-- end::Card Review Process -->

    <!-- start::Card Review Process Time Line -->
    <ReviewProcessTimeLine
      :evaluationTrackings="evaluationTrackings"
    ></ReviewProcessTimeLine>
    <!-- end::Card Review Process Time Line -->

    <!--begin::Modals-->
    <UpdateEvaluationModal
      ref="mdlUpdateEvaluation"
      :evaluation="evaluationData"
      :subjectData="subject"
      @reload-data="getEvaluation"
    ></UpdateEvaluationModal>

    <UpdateSubEvaluationModal
      ref="mdlUpdateSubEvaluation"
      :subEvaluation="selectedSubEvaluation"
      :evaluationDateRange="evaluationDateRange"
      :evaluationTypes="evaluationTypes"
      :canEdit="canEdit"
      :weightLeft="weightLeft"
      @reload-data="getSubEvaluations"
      @clean-selection="unselectSubEvaluation"
    >
    </UpdateSubEvaluationModal>

    <DeleteBaseModal ref="mdlDelete" @delete="deleteSubEvaluation">
      <template v-slot:title>¿Desea eliminar esta evaluación?</template>
      <template v-slot:subject>
        la evaluación
        <span class="font-weight-bolder">{{
          selectedSubEvaluation.evaluation_type
        }}</span>
      </template>
    </DeleteBaseModal>

    <SelectAcademicOrTechnicalGroupModal
      ref="mdlSelectAcademicOrTechnicalGroup"
      :subjectTeacherId="evaluation.subject_teacher_id"
      :gradeId="subject.grade_id"
      :specialityId="subject.speciality_id"
      :subEvaluationId="selectedSubEvaluation.id"
      :subEvaluationLimitDate="selectedSubEvaluation.limit_date_parsed"
      :subEvaluationLimitDateExtensionDetail="
        selectedSubEvaluation.limit_date_extension_detail
      "
      :isOutOfTime="selectedSubEvaluation.is_out_of_time"
      :canSeeScores="
        existInArray('Ver notas o niveles de logros', currentPageActions)
      "
      :canEnterScores="
        existInArray('Ingresar notas o niveles de logros', currentPageActions)
      "
      :academicLevelId="subject.academic_level_id"
    >
    </SelectAcademicOrTechnicalGroupModal>

    <!--end::Modals-->
  </div>
</template>

<script>
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import { mapGetters } from "vuex";
import DeleteBaseModal from "@/components/modals/DeleteBaseModal.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SubEvaluationCard from "@/components/elements/evaluation/SubEvaluationCard.vue";
import ReviewProccessCard from "@/components/elements/evaluation/ReviewProccessCard.vue";
import subjectRepository from "@/repositories/subjectRepository";
import evaluationRepository from "@/repositories/evaluationRepository";
import subEvaluationRepository from "@/repositories/subEvaluationRepository";
import evaluationTrackingRepository from "@/repositories/evaluationTrackingRepository";
import evaluationTypeRepository from "@/repositories/evaluationTypeRepository";
import SubjectInformationCard from "@/components/elements/evaluation/SubjectInformationCard.vue";
import UpdateSubEvaluationModal from "@/components/modals/evaluation/UpdateSubEvaluationModal.vue";
import UpdateEvaluationModal from "@/components/modals/evaluation/UpdateEvaluationModal.vue";
import DeleteSubEvaluationModal from "@/components/modals/evaluation/DeleteSubEvaluationModal.vue";
import SelectAcademicOrTechnicalGroupModal from "@/components/modals/evaluation/SelectAcademicOrTechnicalGroupModal.vue";
import ReviewProcessTimeLine from "@/components/elements/evaluation/ReviewProcessTimeLine.vue";
import Swal from "sweetalert2";

export default {
  name: "ShowEvaluation",
  title: "Perfil | GE ITR",
  components: {
    SubEvaluationCard,
    SubjectInformationCard,
    ReviewProccessCard,
    UpdateSubEvaluationModal,
    UpdateEvaluationModal,
    DeleteSubEvaluationModal,
    SelectAcademicOrTechnicalGroupModal,
    DeleteBaseModal,
    ReviewProcessTimeLine,
  },
  data() {
    return {
      evaluation: {},
      subEvaluations: [],
      evaluationTrackings: [],
      subject: {},
      editMode: false,
      isLoading: false,
      isLoadingSubEvaluations: true,
      isRejected: false,
      selectedSubEvaluation: {},
      evaluationTypes: [],
    };
  },
  props: {
    // s de subject, se guarda el id de la asignatura
    s: {
      type: String,
    },
    // e de evaluation se guarda el id de la evaluación
    e: {
      type: String,
    },
    // f de from, se especifica desde la vista que viene
    f: {
      type: String,
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Perfiles", route: "evaluations" },
      { title: "Ver perfil", route: "show_evaluation" },
    ]);
    this.getEvaluation();
    this.getSubEvaluations();
    this.getSubject();
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Información del perfil");
  },
  methods: {
    toggleMode() {
      this.editMode = !this.editMode;
    },
    getSubEvaluations() {
      this.isLoadingSubEvaluations = true;

      subEvaluationRepository
        .getSubEvaluationsByEvaluation(this.e)
        .then(({ data }) => {
          this.subEvaluations = data;
        })
        .catch(() => {
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.isLoadingSubEvaluations = false;
        });
    },
    getSubject() {
      subjectRepository
        .getSubject(this.s)
        .then(({ data }) => {
          this.subject = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },
    getEvaluation() {
      this.isLoading = true;
      evaluationRepository
        .getEvaluation(this.e)
        .then(({ data }) => {
          this.evaluation = data;
          this.evaluationTrackings = data.evaluation_trackings;
          this.getEvaluationTypesOfActivityType();
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    goBack() {
      if (this.canReview) {
        Swal.fire({
          title: "¿Estas seguro que deseas regresar?",
          text:
            "Si regresas a la página anterior el proceso de revisión quedará sin concluir",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sí, regresar",
          cancelButtonText: "Cancelar",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.go(-1);
          }
        });
      } else {
        this.$router.go(-1);
      }
    },
    setSubEvaluation(subEvaluation) {
      this.selectedSubEvaluation = { ...subEvaluation };
    },
    showMdlUpdateSubEvaluation() {
      this.$refs.mdlUpdateSubEvaluation.toggleModal();
    },
    showMdlSelectAcademicOrTechnicalGroup() {
      this.$refs.mdlSelectAcademicOrTechnicalGroup.toggleModal();
    },
    showMdlDelete() {
      this.$refs.mdlDelete.toggleModal();
    },
    showMdlUpdateEvaluation() {
      this.$refs.mdlUpdateEvaluation.toggleModal();
    },
    deleteSubEvaluation() {
      subEvaluationRepository
        .deleteSubEvaluation(this.selectedSubEvaluation.id)
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data if operation has been completed
          if (data.status) {
            this.getSubEvaluations();
          }
        })
        .catch((error) => {
          // Sweetalert that indicates the operation was unsuccesfull
          this.fireToast({
            icon: "error",
            title: `No es posible eliminar el registro seleccionado en estos momentos 🙁`,
          });
        });
    },

    getEvaluationTypesOfActivityType() {
      evaluationTypeRepository
        .getEvaluationTypesByActivityType(this.evaluation.activity_type_id)
        .then(({ data }) => {
          this.evaluationTypes = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },

    unselectSubEvaluation() {
      this.selectedSubEvaluation = {};
    },
  },
  computed: {
    ...mapGetters([
      "currentPageActions",
      "currentUserPersonalInfo",
      "currentUser",
    ]),
    canEdit() {
      return (
        (this.evaluation.evaluation_status_id == 3 ||
          this.evaluation.evaluation_status_id == 1) &&
        this.existInArray("Editar", this.currentPageActions)
      );
    },
    canReview() {
      return (
        this.evaluation.evaluation_status_id == 2 &&
        this.f == "evaluations" &&
        this.existInArray("Revisar perfil", this.currentPageActions)
      );
    },
    canEnableReview() {
      return (
        this.f == "evaluations" &&
        this.existInArray("Revisar perfil", this.currentPageActions)
      );
    },
    canDeleteSubEvaluation() {
      return (
        this.subEvaluations.length > 1 &&
        this.evaluation.evaluation_status_id == 3 &&
        this.existInArray("Eliminar evaluaciones", this.currentPageActions)
      );
    },
    showEnterScores() {
      return (
        this.evaluation.evaluation_status_id == 5 &&
        this.existInArray("Gestión de notas", this.currentPageActions)
      );
    },
    orderedColors() {
      return this.colors.sort(() => Math.random() - 0.5);
    },
    evaluationDateRange() {
      const range = {
        startDate: this.evaluation.start_date,
        endDate: this.evaluation.end_date,
      };
      return range;
    },
    weightLeft() {
      const acummulatedWeight = this.subEvaluations.reduce((prev, curr) => {
        return (prev += parseInt(curr.percent) || 0);
      }, 0);
      return this.evaluation.percent - acummulatedWeight;
    },
    evaluationInstrumentURL() {
      let baseURL;
      if (process.env.APP_ENV === "production") {
        baseURL = process.env.API_URL;
      } else if (process.env.APP_ENV === "development") {
        baseURL = process.env.DEV_API_URL;
      } else {
        baseURL = process.env.LOCAL_API_URL;
      }
      return baseURL + "/evaluations/evaluation-instrument/";
    },
    evaluationData() {
      return {
        ...this.evaluation,
      };
    },
  },
  watch: {
    e(newValue, oldValue) {
      if (newValue) {
        this.evaluationTrackings = [];
        this.getEvaluation();
        this.getSubEvaluations();
      }
    },
  },
  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
};
</script>

<style>
.fixed-height {
  height: 100px;
  overflow-y: scroll;
  scrollbar-width: thin;
  padding: 5px 10px;
  scrollbar-color: #f5f5f5 #dfdfdf;
}
</style>
