import ApiService from "@/core/services/api.service";

/**
 * POST request to submit review finish notification
 * @param payload
 * @returns {*}
 */
export const finishReviewProccess = (payload) => {
    return ApiService.post("evaluation_review_notifications/finish-review-proccess", payload);
};
/**
 * POST request to submit correction notification
 * @param payload
 * @returns {*}
 */
export const finishCorrection = (payload) => {
    return ApiService.post("evaluation_review_notifications/evaluation-corrected", payload);
};

export default {
    finishReviewProccess,
    finishCorrection,
};
