<template>
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      transition="dialog-bottom-transition"
      max-width="768"
    >
      <v-form>
        <v-card>
          <v-toolbar dark color="deep-purple" class="elevation-0 px-3">
            <span class="headline">Grupos académicos o técnicos</span>
          </v-toolbar>
          <v-card-text>
            <v-container v-if="isLoadingGroups" style="height: 200px">
              <v-row
                class="fill-height"
                align-content="center"
                justify="center"
              >
                <v-col class="text-subtitle-1 text-center" cols="12">
                  Cargando grupos académicos y técnicos
                </v-col>
                <v-col cols="6">
                  <v-progress-linear
                    color="deep-purple darken-3"
                    indeterminate
                    rounded
                    height="6"
                  ></v-progress-linear>
                </v-col>
              </v-row>
            </v-container>
            <v-container
              v-else-if="
                !isLoadingGroups &&
                technicalGroups.length == 0 &&
                academicGroups.length == 0
              "
              style="height: 200px"
            >
              <v-row
                class="fill-height"
                align-content="center"
                justify="center"
              >
                <v-col class="text-subtitle-1 text-center" cols="12">
                  No hay ningún grupo académico o técnico
                </v-col>
              </v-row>
            </v-container>
            <div v-else class="card-body">
              <v-container v-if="isOutOfTime">
                <v-row no-gutters>
                  <v-col>
                    <v-card
                      outlined
                      class="mb-4 px-5 py-4 d-flex flex-column flex-sm-row rounded-lg justify-center align-start"
                      color="red lighten-5"
                    >
                      <v-icon x-large color="red"> mdi-timer-alert </v-icon>
                      <div class="px-4">
                        <h2 class="red--text">
                          El periodo hábil de ingreso de calificaciones para
                          esta evaluación ha finalizado.
                        </h2>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
              <!-- begin::Info about limit date extension -->
              <v-container v-if="hasLimitDateExtension && !isOutOfTime">
                <v-row no-gutters>
                  <v-col>
                    <v-card
                      outlined
                      class="mb-4 px-5 py-4 d-flex flex-column flex-sm-row rounded-lg justify-center align-start"
                      color="orange lighten-5"
                    >
                      <v-icon x-large color="orange">
                        mdi-timer-lock-open
                      </v-icon>
                      <div class="px-4">
                        <h2 class="black--text text--accent-4">
                          El periodo hábil de ingreso de calificaciones para
                          esta evaluación ha sido extendido, la nueva fecha
                          límite para ingresar calificaciones es el
                          <span class="font-weight-bold">
                            {{
                              subEvaluationLimitDateExtensionDetail.new_limit_date_formatted
                            }} </span
                          >.
                        </h2>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
              <!-- end::Info about limit date extension -->
              <v-container v-if="academicGroups.length > 0">
                <v-row>
                  <v-col tag="h6">Grupos académicos</v-col>
                </v-row>
                <v-row>
                  <AcademicGroupCardEle
                    v-for="(academicGroup, index) in academicGroups"
                    :key="`a-g-${index}`"
                    :group="academicGroup"
                    :subEvaluationId="subEvaluationId"
                    :canSeeScores="canSeeScores"
                    :canEnterScores="canEnterScores"
                    :isOutOfTime="isOutOfTime"
                    @show-upload-excel="showMdlUploadExcelWithScores"
                  ></AcademicGroupCardEle>
                </v-row>
              </v-container>
              <v-container v-if="technicalGroups.length > 0">
                <v-row>
                  <v-col tag="h6">Grupos técnicos</v-col>
                </v-row>
                <v-row>
                  <TechnicalGroupCardEle
                    v-for="(technicalGroup, index) in technicalGroups"
                    :key="`t-g-${index}`"
                    :group="technicalGroup"
                    :subEvaluationId="subEvaluationId"
                    :canSeeScores="canSeeScores"
                    :canEnterScores="canEnterScores"
                    :isOutOfTime="isOutOfTime"
                    @show-upload-excel="showMdlUploadExcelWithScores"
                  ></TechnicalGroupCardEle>
                </v-row>
              </v-container>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end pt-0">
            <v-btn text outlined class="mr-1" @click="closeModal">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <UploadExcelWithScoresModal
      ref="mdlUploadExcelWithScores"
      :subEvaluationId="subEvaluationId"
    ></UploadExcelWithScoresModal>
  </div>
</template>

<script>
import subjectTeacherRepository from "@/repositories/subjectTeacherRepository";
import academicGroupRepository from "@/repositories/academicGroupRepository";
import AcademicGroupCardEle from "@/components/elements/evaluation/AcademicGroupCardEle.vue";
import TechnicalGroupCardEle from "@/components/elements/evaluation/TechnicalGroupCardEle.vue";
import UploadExcelWithScoresModal from "@/components/modals/evaluation/UploadExcelWithScoresModal.vue";

export default {
  name: "SelectAcademicGroupModal",
  components: {
    AcademicGroupCardEle,
    TechnicalGroupCardEle,
    UploadExcelWithScoresModal,
  },
  data() {
    return {
      isLoading: false,
      isLoadingGroups: true,
      dialog: false,
      academicGroups: [],
      technicalGroups: [],
      gradeSections: {},
    };
  },
  props: {
    subjectTeacherId: {
      type: Number,
    },
    gradeId: {
      type: Number,
    },
    specialityId: {
      type: Number,
    },
    subEvaluationId: {
      type: Number,
    },
    subEvaluationLimitDate: {
      type: String,
    },
    subEvaluationLimitDateExtensionDetail: {
      type: Object,
    },
    canSeeScores: {
      type: Boolean,
    },
    canEnterScores: {
      type: Boolean,
    },
    isOutOfTime: {
      type: Boolean,
    },
    academicLevelId: {
      type: Number,
    },
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },

    closeModal() {
      this.toggleModal();
    },

    getGroups() {
      this.isLoadingGroups = true;
      subjectTeacherRepository
        .getSubjectTeacherWithGroups(this.subjectTeacherId)
        .then(({ data }) => {
          // Se recorren los teacher_groups
          data.teacher_groups.forEach((element) => {
            // Se verifica si tiene esa key en el objecto groupable para saber si es grupo académico y si no, es técnico
            if ("section_group_id" in element.groupable) {
              this.academicGroups.push({
                id: element.teacher_groupable_id,
                sectionGroup: `${element.groupable.section_group.section.name}-${element.groupable.section_group.group.name}`,
                grade: element.groupable.grades?.name,
              });
            } else if ("speciality_id" in element.groupable) {
              // Por cada sección del grado se crea una un grupo con el grupo de la especialidad ejemplo Software grupo 1, los divide de la siguiente forma: Software Grupo 1A, Software Grupo 1B

              // Hay dos casos diferentes si es grupo técnico

              // Caso 1: Si es tercer ciclo (academicLevelId = 1) solo se crea para la sección académica que coincide con la especialidad
              // Por ejemplo: Especialidad B grupo 1, de todas las secciones de Septimo (A,B,C,D), solo se tomaría la B que coincide con el de la especialidad
              if (this.academicLevelId == 1) {
                const teacherGroup = {
                  id: element.teacher_groupable_id,
                  technicalGroup: `${element.groupable.speciality.name}-${element.groupable.groups.name}`,
                  academicGroups:
                    this.gradeSections[element.groupable.speciality.name],
                  section: element.groupable.speciality.name,
                  grade: element.groupable.grades?.name,
                };
                this.technicalGroups.push(teacherGroup);
                return;
              }

              // Caso 2: se crea un item por todas las secciones academicas disponibles
              Object.entries(this.gradeSections).forEach(([key, value]) => {
                this.technicalGroups.push({
                  id: element.teacher_groupable_id,
                  technicalGroup: `${element.groupable.groups.name}-${key}`,
                  academicGroups: value,
                  section: key,
                  grade: element.groupable.grades?.name,
                });
              });
            }
          });
        })
        .catch(() => {
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.isLoadingGroups = false;
        });
    },
    getSectionsOfAcademicGroups() {
      academicGroupRepository
        .getSectionsOfAcademicGroups({
          grade_id: this.gradeId,
          speciality_id: this.specialityId,
        })
        .then(({ data }) => {
          this.gradeSections = data;
          this.getGroups();
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },

    showMdlUploadExcelWithScores() {
      this.$refs.mdlUploadExcelWithScores.toggleModal();
    },
  },
  computed: {
    hasLimitDateExtension() {
      return !!this.subEvaluationLimitDateExtensionDetail;
    },
  },
  watch: {
    dialog(newValue, oldValue) {
      // If modal was open, make request for fill sections and sectionGroups
      if (newValue) {
        this.academicGroups = [];
        this.technicalGroups = [];
        this.getSectionsOfAcademicGroups();
      }
    },
  },
};
</script>
