<template>
  <div class="card card-custom gutter-b">
    <div class="card-header flex-nowrap border-0">
      <div class="card-title">
        <h3 class="card-label">
          Proceso de revisión del perfil
        </h3>
      </div>
      <div class="card-toolbar"></div>
    </div>
    <v-divider class="my-0"></v-divider>
    <div class="card-body">
      <v-container>
        <v-row v-if="evaluationStatusId == 1">
          <v-col>
            <v-alert outlined type="warning" prominent border="left">
              <span class="text-h5 font-weight-bold">
                Revisión del perfil
              </span>
              <div>
                Actualmente el perfil está pendiente de ser revisado.
              </div>
            </v-alert>
          </v-col>
        </v-row>

        <v-row v-else-if="evaluationStatusId == 2">
          <v-col>
            <v-alert
              outlined
              type="info"
              :icon="!canReview"
              prominent
              border="left"
            >
              <v-card-title class="text-h5 font-weight-bold">
                Revisión del perfil
              </v-card-title>

              <v-card-subtitle class="text-h6 font-weight-normal">
                Actualmente el perfil está siendo
                <span class="font-weight-medium">
                  revisado.
                </span>
              </v-card-subtitle>
              <v-card-text class="pb-0" v-if="canReview">
                <div class="text-body-1">Observación general del perfil</div>
                <div class="mt-2">
                  <form-group name="observación" :validator="$v.observations">
                    <v-textarea
                      slot-scope="{ attrs }"
                      v-bind="attrs"
                      solo
                      counter
                      label="Observación general"
                      auto-grow
                      rows="2"
                      color="white"
                      v-model="observations"
                    ></v-textarea>
                  </form-group>
                </div>
              </v-card-text>
              <v-card-actions class="mx-2" v-if="canReview">
                <v-btn
                  dark
                  color="info"
                  class="mb-3"
                  @click="finishReviewConfirmation"
                  :loading="isLoadingFinishReview"
                >
                  Finalizar revisión
                </v-btn>
              </v-card-actions>
            </v-alert>
          </v-col>
        </v-row>

        <v-row v-else-if="evaluationStatusId == 3">
          <v-col>
            <v-alert
              outlined
              type="error"
              :icon="!canEdit"
              prominent
              border="left"
            >
              <v-card-title class="text-h5 font-weight-bold">
                Revisión del perfil
              </v-card-title>

              <v-card-subtitle class="text-h6 font-weight-normal">
                Actualmente el perfil tiene observaciones pendientes de ser
                corregidas.
              </v-card-subtitle>
              <v-card-text class="pb-0 text-body-1" v-if="canEdit">
                <div class="text-h6 font-weight-normal">
                  ¿Desea confirmar que se han realizado las correciones?
                </div>
                <div class="text--primary my-1">
                  Si la información del perfil ha sido modificada de acuerdo a
                  las observaciones indicadas presione el botón
                  <span class="font-weight-medium">
                    "Confirmar corrección"
                  </span>
                  para notificar al departamento de pedagogía de ello y
                  continuar con el proceso revisión del perfil.
                </div>
              </v-card-text>
              <v-card-actions class="mx-2" v-if="canEdit">
                <v-btn
                  dark
                  color="error"
                  class="mb-3"
                  :loading="isLoadingFinishCorrection"
                  @click="finishCorrection"
                >
                  Confirmar corrección
                </v-btn>
              </v-card-actions>
            </v-alert>
          </v-col>
        </v-row>

        <v-row v-else-if="evaluationStatusId == 4">
          <v-col>
            <v-alert
              outlined
              type="warning"
              prominent
              border="left"
              :icon="!canEnableReview"
            >
              <v-card-title class="text-h5 font-weight-bold">
                Revisión del perfil
              </v-card-title>

              <v-card-subtitle class="text-h6 font-weight-normal">
                Actualmente el perfil tiene correcciones pendientes de ser
                revisadas.
              </v-card-subtitle>

              <v-card-actions v-if="canEnableReview" class="mx-2">
                <v-btn dark color="warning" class="mb-3" @click="enableReview">
                  Revisar perfil
                </v-btn>
              </v-card-actions>
            </v-alert>
          </v-col>
        </v-row>

        <v-row v-else-if="evaluationStatusId == 5">
          <v-col>
            <v-alert outlined type="success" prominent border="left">
              <v-card-title class="text-h5 font-weight-bold">
                Revisión del perfil
              </v-card-title>

              <v-card-subtitle class="text-h6 font-weight-normal">
                El perfil ha sido
                <span class="font-weight-medium">
                  aprobado.
                </span>
                Una vez aprobado es posible ingresar notas.
              </v-card-subtitle>
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import evaluationReviewNotificationRepository from "@/repositories/evaluationReviewNotificationRepository";
import evaluationTrackingRepository from "@/repositories/evaluationTrackingRepository";
import JwtService from "@/core/services/jwt.service";
import { requiredIf, maxLength } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  name: "ReviewProccessCard",
  data() {
    return {
      isRejected: false,
      observations: null,
      isLoadingFinishReview: false,
      isLoadingFinishCorrection: false,
    };
  },
  props: {
    evaluationId: {
      type: Number,
    },
    evaluationStatusId: {
      type: Number,
    },
    canReview: {
      type: Boolean,
      default: true,
    },
    canEnableReview: {
      type: Boolean,
      default: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    finishCorrection() {
      this.isLoadingFinishCorrection = true;
      const data = {
        evaluation_id: this.evaluationId,
        token: JwtService.getToken(),
        user_id: this.currentUserPersonalInfo.id_user,
      };
      evaluationReviewNotificationRepository
        .finishCorrection(data)
        .then((response) => {
          if (response.status == 204) {
            Swal.fire({
              title: "¡Éxito!",
              text: "Confirmación realizada correctamente",
              icon: "success",
            }).then((result) => {
              if (result.isConfirmed) {
                this.$router.go(-1);
              }
            });
          }
        })
        .catch(() => {
          this.sweetAlertResponse({
            status: false,
            message: "No ha sido posible realizar la confirmación",
          });
        })
        .finally(() => {
          this.isLoadingFinishCorrection = false;
        });
    },
    finishReview(wasApproved) {
      this.isLoadingFinishReview = true;
      let payload = {
        token: JwtService.getToken(),
        evaluation_id: this.evaluationId,
        user_id: this.currentUserPersonalInfo.id_user,
        was_approved: wasApproved,
        observations: this.observations,
      };
      let successMsg;
      if (wasApproved) {
        this.isRejected = false;
        successMsg = "El perfil ha sido aprobado correctamente";
      } else {
        this.isRejected = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.fireToast({
            icon: "error",
            title: "Asegurate de agregar las respectivas observaciones",
          });
          this.isLoadingFinishReview = false;
          return;
        }
        successMsg =
          "El perfil no ha sido aprobado, el docente ha sido notificado y tendrá que realizar las respectivas correcciones";
      }

      evaluationReviewNotificationRepository
        .finishReviewProccess(payload)
        .then((response) => {
          if (response.status == 204) {
            Swal.fire({
              title: "¡Éxito!",
              text: successMsg,
              icon: "success",
            }).then((result) => {
              if (result.isConfirmed) {
                this.$router.go(-1);
              }
            });
          }
        })
        .catch(() => {
          this.sweetAlertResponse({
            status: false,
            message: "No ha sido posible realizar la confirmación",
          });
        })
        .finally(() => {
          this.isLoadingFinishReview = false;
        });
    },
    finishReviewConfirmation() {
      Swal.fire({
        title: "¿Deseas finalizar el proceso de revisión?",
        text:
          "Decide si el perfil esta listo y puede ser aprobado o si no ya que requiere de modifaciones por parte del docente quien será notificado automáticamente por el sistema",
        icon: "info",
        width: 550,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonColor: "#0abb87",
        denyButtonColor: "#F44336",

        confirmButtonText: "Aprobar",
        denyButtonText: "No aprobar",
        cancelButtonText: "Cancelar",
        customClass: {
          denyButton: "danger-outlined",
          cancelButton: "cancel-btn",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.finishReview(true);
        } else if (result.isDenied) {
          this.finishReview(false);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // Do something after cancel btn was clicked
        }
      });
    },
    enableReview() {
      // Agregando registro a la bitacora de seguimiento del perfil con el id del estado correspondiente a En revisión
      let payload = {
        evaluation_id: this.evaluationId,
        evaluation_status_id: 2,
        user_id: this.currentUserPersonalInfo.id_user,
      };
      evaluationTrackingRepository
        .createEvaluationTracking(payload)
        .then(() => {
          this.$emit("reload-data");
        });
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
  },
  validations: {
    observations: {
      required: requiredIf(function() {
        return !!this.isRejected;
      }),
    },
  },
};
</script>

<style>
.danger-outlined {
  border: 1px solid #f44336 !important;
  color: red !important;
  background: transparent !important;
  transition: 0.2s;
}

.danger-outlined:hover {
  background: #f44336 !important;
  color: white !important;
}

.cancel-btn {
  border: 1px solid transparent !important;
  background: transparent !important;
  transition: 0.2s !important;
  color: #696969 !important;
}

.cancel-btn:hover {
  border: 1px solid #696969 !important;
}
</style>
