<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="700"
    persistent
    scrollable
  >
    <v-form>
      <v-card>
        <v-toolbar dark color="green darken-4" class="elevation-0 px-3">
          <span class="headline">Detalle de errores al procesar notas</span>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-alert shaped text prominent color="warning">
                  <div class="text-h5 font-weight-bold mb-2">
                    ¡Atención!
                  </div>
                  <p>
                    Es importante que corrijas lo que se indica, una vez
                    corregido deberias de volver a subir el excel.
                  </p>
                </v-alert>
              </v-col>
            </v-row>
            <v-row>
              <v-col tag="p" class="text-body-1">
                A continuación se detalla cada uno de los errores detectados al
                momento de procesar el archivo excel
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                v-for="(item, index) in errorList"
                :key="index"
              >
                <v-card
                  class="mx-auto"
                  max-width="344"
                  outlined
                  color="red lighten-5"
                >
                  <v-list-item>
                    <v-list-item-content>
                      <div class="text-overline">
                        Fila
                      </div>
                      <v-list-item-title class="text-h4 mb-1">
                        {{ item.row }}
                      </v-list-item-title>
                      <v-list-item-title class="mb-1 text-wrap">
                        Código:
                        <span class="text-h6">
                          {{ item.values["codigo"] }}
                        </span>
                        •
                        {{ capitalize(item.attribute) }}:
                        <span class="text-h6">
                          {{
                            item.values[getKey(item.attribute)] != null
                              ? item.values[getKey(item.attribute)]
                              : "ninguno"
                          }}
                        </span>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <ul class="pa-0">
                          <li
                            v-for="(error, index) in item.errors"
                            :key="index"
                            class="text-body-1 text-wrap"
                          >
                            {{ error }}
                          </li>
                        </ul>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider class="mt-0"></v-divider>
        <v-card-actions class="justify-end pt-0">
          <v-btn text outlined class="mr-1" @click="toggleModal"
            >Entendido</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: "UploadExcelErrorDetailModal",
  data() {
    return {
      isLoading: false,
      dialog: false,
    };
  },
  props: {
    errorList: {
      type: Array,
    },
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },
    capitalize(string) {
      return (string && string[0].toUpperCase() + string.slice(1)) || "";
    },
    getKey(string) {
      return string.replaceAll(" ", "_");
    },
  },
};
</script>
