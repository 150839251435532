<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      max-width="700"
      persistent
      scrollable
    >
      <v-form>
        <v-card>
          <v-toolbar dark color="green darken-4" class="elevation-0 px-3">
            <span class="headline">Carga de excel con notas</span>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-alert shaped text prominent color="info">
                    <div class="text-h5 font-weight-bold mb-2">
                      ¡Atención!
                    </div>
                    <p>
                      Antes de subir el excel con notas debes tomar en cuenta lo
                      siguiente:
                      <br />
                    </p>
                    <ul>
                      <li>
                        El excel debe ser estrictamente el formato que
                        proporciona el sistema.
                      </li>
                      <li>
                        Si el campo de la nota viene vacío, es un cero o es NPI el sistema
                        descartará la nota de ese estudiante y solo guardará las notas válidas.
                      </li>
                      <li>
                        A la hora de guardar la nota el sistema solo toma en
                        cuenta un decimal.
                      </li>
                    </ul>
                  </v-alert>
                </v-col>
              </v-row>
              <v-row class="px-4 pt-6">
                <v-col class="py-0" cols="12">
                  <div class="black--text mb-1 flex-column">
                    <form-group
                      name="excel con las notas de la evaluación"
                      :validator="$v.subEvaluation.score_student_sub_evaluation"
                    >
                      <v-file-input
                        slot-scope="{ attrs }"
                        v-bind="attrs"
                        label="Seleccionar excel con las notas de la evaluación"
                        prepend-icon=""
                        prepend-inner-icon="mdi-file-excel-box"
                        filled
                        rounded
                        dense
                        background-color="grey lighten-4"
                        color="grey darken-3"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        truncate-length="15"
                        v-model="subEvaluation.score_student_sub_evaluation"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="green darken-4"
                            dark
                            label
                            small
                            >{{ text }}</v-chip
                          >
                        </template>
                      </v-file-input>
                    </form-group>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider class="mt-0"></v-divider>
          <v-card-actions class="justify-end pt-0">
            <v-btn text outlined class="mr-1" @click="closeModal">Cerrar</v-btn>
            <v-btn
              color="green darken-4"
              dark
              depressed
              :loading="isLoading"
              @click="submit"
              >subir excel</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <UploadExcelErrorDetailModal
      ref="mdlUploadExcelErrorDetail"
      :errorList="errors"
    ></UploadExcelErrorDetailModal>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import subEvaluationRepository from "@/repositories/subEvaluationRepository";
import UploadExcelErrorDetailModal from "@/components/modals/evaluation/UploadExcelErrorDetailModal.vue";
import Swal from "sweetalert2";

export default {
  name: "UploadExcelWithScoresModal",
  components: {
    UploadExcelErrorDetailModal,
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      subEvaluation: {},
      errors: [],
    };
  },
  props: {
    subEvaluationId: {
      type: Number,
    },
  },
  methods: {
    upload() {
      this.isLoading = true;
      subEvaluationRepository
        .uploadSubEvaluationExcelWithScores(
          this.subEvaluationId,
          this.objectToFormData(this.subEvaluation)
        )
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data, hidding modal and cleaning selection if operation has been completed
          if (data.status) {
            this.closeModal();
          }
        })
        .catch((error) => {
          if (!error) {
            console.error(error);
            this.fireToast({
              icon: "error",
              title: "No ha sido posible actualizar el registro 🙁",
            });
            return;
          }
          if ("response" in error && error.response.status == 422) {
            const {
              data: { data: errors },
            } = error.response;

            Swal.fire({
              title: "¡Oops!",
              text:
                "Parece que hubo un problema al procesar parte de las notas ingresadas",
              icon: "error",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Ver más detalle",
            }).then((result) => {
              if (result.isConfirmed) {
                this.errors = errors;
                this.showMdlUploadExcelErrorDetail();
              }
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar el formulario correctamente",
        });
        return;
      }
      this.upload();
    },

    toggleModal() {
      this.dialog = !this.dialog;
    },

    closeModal() {
      this.toggleModal();
      this.$v.$reset();
      this.subEvaluation = {};
    },

    showMdlUploadExcelErrorDetail() {
      this.$refs.mdlUploadExcelErrorDetail.toggleModal();
    },
  },
  validations: {
    subEvaluation: {
      score_student_sub_evaluation: {
        required,
      },
    },
  },
};
</script>
