<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="550"
    persistent
    scrollable
  >
    <v-form>
      <v-card>
        <v-toolbar dark color="primary" class="elevation-0 px-3">
          <span class="headline">Editar detalles de la evaluación</span>
        </v-toolbar>
        <v-card-text>
          <v-container class="text-body-1 mx-2 pt-4">
            <v-row class="mt-1 mr-2">
              <v-col cols="12" class="py-0">
                <div class="black--text mb-1 flex-column">
                  <form-group
                    name="descripción"
                    :validator="$v.evaluation.description"
                  >
                    <v-textarea
                      slot-scope="{ attrs }"
                      v-bind="attrs"
                      filled
                      rounded
                      dense
                      background-color="grey lighten-4"
                      color="black"
                      counter
                      label="Descripción general de la situación de aprendizaje del
                    perfil"
                      placeholder="Descripción del perfil"
                      auto-grow
                      rows="2"
                      v-model="evaluation.description"
                    ></v-textarea>
                  </form-group>
                </div>
              </v-col>
            </v-row>
            <v-row class="mr-2">
              <v-col cols="12" sm="6" class="py-0">
                <div class="black--text mb-1 flex-column">
                  <v-menu
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    v-model="startDateMenu"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <form-group
                        name="fecha de inicio"
                        :validator="$v.evaluation.start_date"
                        v-bind="attrs"
                      >
                        <v-text-field
                          v-model="evaluation.start_date"
                          label="Fecha de inicio"
                          placeholder="Fecha de inicio"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          required
                          filled
                          rounded
                          dense
                          background-color="grey lighten-4"
                          color="black"
                          v-bind="attrs"
                          slot-scope="{ attrs }"
                          v-on="on"
                        ></v-text-field>
                      </form-group>
                    </template>
                    <v-date-picker
                      v-model="evaluation.start_date"
                      :min="new Date().toISOString().slice(0, 10)"
                      :max="evaluation.end_date"
                      @input="
                        startDateMenu = false;
                        $emit('set-start-date', evaluation.start_date);
                      "
                    ></v-date-picker>
                  </v-menu>
                </div>
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <div class="black--text mb-1 flex-column">
                  <v-menu
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    v-model="endDateMenu"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <form-group
                        name="fecha de finalización"
                        :validator="$v.evaluation.end_date"
                        v-bind="attrs"
                      >
                        <v-text-field
                          v-model="evaluation.end_date"
                          label="Fecha de finalización"
                          placeholder="Fecha de finalización"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          required
                          filled
                          rounded
                          dense
                          background-color="grey lighten-4"
                          color="black"
                          :disabled="!evaluation.start_date"
                          v-bind="attrs"
                          slot-scope="{ attrs }"
                          v-on="on"
                        ></v-text-field>
                      </form-group>
                    </template>
                    <v-date-picker
                      v-model="evaluation.end_date"
                      :min="evaluation.start_date"
                      @input="
                        endDateMenu = false;
                        $emit('set-end-date', evaluation.end_date);
                      "
                    ></v-date-picker>
                  </v-menu>
                </div>
              </v-col>
            </v-row>
            <v-row class="mr-2">
              <v-col cols="12" class="py-0">
                <div class="black--text mb-1 flex-column">
                  <form-group
                    name="instrumento de evaluación"
                    :validator="$v.evaluation.evaluation_instrument_document"
                  >
                    <v-file-input
                      slot-scope="{ attrs }"
                      v-bind="attrs"
                      label="Instrumento de evaluación"
                      placeholder="Instrumento de evaluación"
                      prepend-icon=""
                      prepend-inner-icon="mdi-file-pdf-box"
                      filled
                      rounded
                      dense
                      background-color="grey lighten-4"
                      color="black"
                      accept="application/pdf"
                      truncate-length="15"
                      hint="El instrumento de evaluación del perfil es obligatorio"
                      persistent-hint
                      v-model="evaluation.evaluation_instrument_document"
                    >
                      <template v-slot:selection="{ index, text }">
                        <v-chip v-if="index < 2" color="primary" label small>{{
                          text
                        }}</v-chip>
                      </template>
                    </v-file-input>
                  </form-group>
                </div>
              </v-col>
            </v-row>
            <v-row class="pb-2 mr-2">
              <v-col cols="12" class="pb-0">
                <div class="black--text mb-0 flex-column">
                  <div class="text-body-2 mb-1">
                    Modalidad
                  </div>
                  <v-radio-group
                    v-model="evaluation.is_group_activity"
                    class="my-0 py-0"
                    row
                    mandatory
                  >
                    <v-radio label="Individual" :value="0"></v-radio>
                    <v-radio label="Grupal" :value="1"></v-radio>
                  </v-radio-group>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider class="mt-0"></v-divider>
        <v-card-actions class="justify-end pt-0">
          <v-btn text outlined class="mr-1" @click="closeModal">Cerrar</v-btn>
          <v-btn
            color="success"
            outlined
            depressed
            :loading="isLoading"
            @click="submit"
            >guardar cambios</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import evaluationRepository from "@/repositories/evaluationRepository";
import activityCategoryRepository from "@/repositories/activityCategoryRepository";
import activityTypeRepository from "@/repositories/activityTypeRepository";
import academicLoadRepository from "@/repositories/academicLoadRepository";

export default {
  name: "UpdateSubEvaluationModal",
  data() {
    return {
      isLoading: false,
      dialog: false,
      startDateMenu: false,
      endDateMenu: false,
      activityCategories: [],
      activityTypesOfActivityCategory: [],
      academicLoads: [],
      evaluationsOfSubjectAndStageByActivityCategoryDetail: {},
      errorMessages: {
        dontExceedTheMax:
          "La sumatoria de la ponderación junto a la que ya hay acumulada por los otros perfiles creados en esta etapa y categoria no debe exceder la total.",
      },
    };
  },
  props: {
    evaluation: {
      type: Object,
    },
    canEdit: {
      type: Boolean,
    },
    subjectData: {
      type: Object,
    },
  },
  methods: {
    update() {
      this.isLoading = true;
      evaluationRepository
        .updateEvaluation(
          this.evaluation.id,
          this.objectToFormData(this.evaluation)
        )
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data, hidding modal and cleaning selection if operation has been completed
          if (data.status) {
            this.$emit("reload-data");
            this.closeModal();
          }
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible actualizar el registro 🙁",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar el formulario correctamente",
        });
        return;
      }
      this.update();
    },

    toggleModal() {
      this.dialog = !this.dialog;
    },

    closeModal() {
      this.toggleModal();
      this.$v.$reset();
    },

    getEvaluationsOfSubjectStageByActivityCategory() {
      let payload = {
        subject_teacher_id: this.evaluation.subject_teacher_id,
        academic_load_id: this.evaluation.academic_load_id,
        activity_category_id: this.evaluation.activity_category_id,
      };
      evaluationRepository
        .getEvaluationsOfSubjectStageByActivityCategory(payload)
        .then(({ data }) => {
          let numOfEvaluations = data.length;
          let accumulatedPercentage = data.reduce((prev, curr) => {
            return (prev += curr.percent);
          }, 0);
          // Se  obtiene el porcentaje disponible de la categoria seleccionada
          let {
            percentage: totalPercentage,
            number_evaluations: numMaxOfEvaluations,
          } = this.activityCategories.find(
            (item) => item.id == payload.activity_category_id
          );
          // Se calcula el porcentaje disponible de esa categoria
          let availablePercentage = totalPercentage - accumulatedPercentage;
          this.evaluationsOfSubjectAndStageByActivityCategoryDetail = {
            numOfEvaluations,
            numMaxOfEvaluations,
            accumulatedPercentage,
            availablePercentage,
            totalPercentage: parseInt(totalPercentage),
          };
        })
        .catch((error) => {
          console.log(error);
          this.fireToast({
            icon: "error",
            title: "No ha sido posible consultar la información 🙁",
          });
        });
    },

    getAcademicLoadOfSubject(subject_id) {
      academicLoadRepository
        .getAcademicLoadsBySubject(subject_id)
        .then(({ data }) => {
          this.academicLoads = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },

    getActivityCategoriesOfSubjectType(activityCategoryId) {
      activityCategoryRepository
        .getActivityCategoriesBySubjectType(activityCategoryId)
        .then(({ data }) => {
          this.activityCategories = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },

    getActivityTypesOfActivityCategory() {
      const activityCategoryId = this.evaluation.activity_category_id;
      activityTypeRepository
        .getActivityTypesByActivityCategory(activityCategoryId)
        .then(({ data }) => {
          this.activityTypesOfActivityCategory = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },
  },
  computed: {
    canAddMoreEvaluations() {
      if (
        Object.keys(this.evaluationsOfSubjectAndStageByActivityCategoryDetail)
          .length == 0
      ) {
        return true;
      }
      return !(
        this.evaluationsOfSubjectAndStageByActivityCategoryDetail
          .numMaxOfEvaluations ==
        this.evaluationsOfSubjectAndStageByActivityCategoryDetail
          .numOfEvaluations
      );
    },
    subjectType() {
      if ("subject_evaluation_scale" in this.subjectData) {
        return this.subjectData.subject_evaluation_scale.subject_type_id;
      }
      return 0;
    },
  },
  watch: {
    subjectData(newValue) {
      if (newValue) {
        const {
          subject_evaluation_scale: { subject_type_id },
          id: subject_id,
        } = newValue;

        this.getAcademicLoadOfSubject(subject_id);
        this.getActivityCategoriesOfSubjectType(subject_type_id);
      }
    },
    evaluation(newValue) {
      if (newValue) {
        this.getActivityTypesOfActivityCategory();
        this.getEvaluationsOfSubjectStageByActivityCategory();
      }
    },
  },
  validations: {
    evaluation: {
      start_date: {
        required,
      },
      end_date: {
        required,
      },
      academic_load_id: {
        required,
      },
      activity_category_id: {
        required,
      },
      activity_category_activity_type_id: {
        required,
      },
      description: {
        required,
        maxLength: maxLength(500),
      },
      // 🚩 TODO: Validar el porcentaje
      percent: {
        // required,
        // dontExceedTheMax(value) {
        //   if (
        //     !(
        //       "availablePercentage" in
        //       this.evaluationsOfSubjectAndStageByActivityCategoryDetail
        //     )
        //   ) {
        //     return true;
        //   }
        //   return (
        //     value <=
        //     this.evaluationsOfSubjectAndStageByActivityCategoryDetail
        //       .availablePercentage
        //   );
        // },
      },
      evaluation_instrument_document: {},
    },
  },
};
</script>
