<template>
  <v-col cols="12" sm="6" md="4" class="p-0">
    <v-card class="mx-2 mb-4" max-width="300" outlined>
      <v-list-item two-line>
        <v-list-item-content class="ml-2 my-2">
          <v-list-item-title class="text-body-1 mb-1">
            {{ group.grade }}
          </v-list-item-title>
          <p class="text-h5 font-weight-bold mb-2">
            {{ group.technicalGroup }}
          </p>
        </v-list-item-content>

        <v-card-actions
          class="m-0 pt-4 px-0 d-flex justify-end align-items-start align-self-start"
        >
          <v-menu transition="slide-y-transition" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" color="deep-purple darken-3"
                >mdi-dots-vertical</v-icon
              >
            </template>
            <v-list>
              <v-list-item class="pa-0">
                <v-btn
                  v-if="canSeeScores"
                  @click="goToScores('v')"
                  text
                  block
                  class="d-flex justify-start align-center"
                >
                  <span> Ver calificaciones </span>
                </v-btn>
              </v-list-item>

              <v-list-item class="pa-0">
                <v-btn
                  v-if="canEnterScores"
                  @click="goToScores('e')"
                  text
                  block
                  class="d-flex justify-start align-center"
                >
                  <span> Ingresar calificaciones </span>
                </v-btn>
              </v-list-item>

              <v-list-item class="pa-0">
                <v-btn
                  v-if="canEnterScores"
                  :disabled="isOutOfTime"
                  :href="subEvaluationExcelURL"
                  download
                  text
                  block
                  class="d-flex justify-start align-center"
                >
                  <span> Descargar excel de calificaciones </span>
                </v-btn>
              </v-list-item>

              <v-list-item class="pa-0">
                <v-btn
                  v-if="canEnterScores"
                  :disabled="isOutOfTime"
                  @click="$emit('show-upload-excel')"
                  text
                  block
                  class="d-flex justify-start align-center"
                >
                  <span> Subir excel de calificaciones </span>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-actions>
      </v-list-item>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: "TechnicalGroupCardEle",
  props: {
    group: {
      type: Object,
    },
    subEvaluationId: {
      type: Number,
    },
    canSeeScores: {
      type: Boolean,
    },
    canEnterScores: {
      type: Boolean,
    },
    isOutOfTime: {
      type: Boolean,
    },
  },
  methods: {
    goToScores(mode) {
      this.$router.push({
        path: "sub_evaluation_scores",
        query: {
          f: "t-g", // f inicial de from, a-g de technicalGroup
          m: mode, // m inicial de mode, a de view
          g: this.group.id, // g  inicial de group
          s_e: this.subEvaluationId, // e inicial de evaluation
          s: this.group.section,
          // "a-a-g": this.group.academicGroups, // a-a-g de acepted academic groups
        },
      });
    },
  },
  computed: {
    subEvaluationExcelURL() {
      let baseURL;
      if (process.env.APP_ENV === "production") {
        baseURL = process.env.API_URL;
      } else if (process.env.APP_ENV === "development") {
        baseURL = process.env.DEV_API_URL;
      } else {
        baseURL = process.env.LOCAL_API_URL;
      }
      return `${baseURL}/sub-evaluations/excel/technical-group/${this.group.id}/section/${this.group.section}/sub-evaluation/${this.subEvaluationId}`;
    },
  },
};
</script>
<style scoped>
.custom-btn {
  text-align: left !important;
}
</style>
