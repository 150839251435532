import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllEvaluationTrackings = () => {
  return ApiService.get("evaluation-trackings");
};


/**
 * GET request to fetch a EvaluationTrackings by evaluation
 * @returns {*}
 */
export const getEvaluationTrackingsByEvaluation = (id) => {
  return ApiService.get("evaluation-trackings/evaluation", id);
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getEvaluationTracking = (id) => {
  return ApiService.get("evaluation-trackings", id);
};

/**
 * POST request to create a new EvaluationTracking
 * @param payload
 * @returns {*}
 */
export const createEvaluationTracking = (payload) => {
  return ApiService.post("evaluation-trackings", payload);
};

/**
 * PUT request to update a specific EvaluationTracking
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateEvaluationTracking = (id, payload) => {
  return ApiService.update("evaluation-trackings", id, payload);
};

/**
 * DELETE request to delete the specified EvaluationTracking
 * @param id
 * @returns {*}
 */
export const deleteEvaluationTracking = (id) => {
  return ApiService.delete(`evaluation-trackings/${id}`);
};

/**
 * GET request to change status of the specified EvaluationTracking
 * @param id
 * @returns {*}
 */
export const changeStatusEvaluationTracking = (id) => {
  return ApiService.get("evaluation-trackings/status", id);
};

export default {
  getAllEvaluationTrackings,
  getEvaluationTrackingsByEvaluation,
  getEvaluationTracking,
  createEvaluationTracking,
  updateEvaluationTracking,
  deleteEvaluationTracking,
  changeStatusEvaluationTracking,
};
